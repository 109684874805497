import React from 'react';
import {observer} from 'mobx-react';
import ModalBase from "./ModalBase";
import axios from "axios";
import {Button, Form, FormControl, Modal} from "react-bootstrap";
import {makeObservable, observable} from "mobx";
import {MdClear} from "react-icons/md";
import notificationStore, {NOTIFICATION_TYPE} from "../../store/NotificationStore";


class MediaDeleteStore {

    constructor() {
        makeObservable(this);
    }

    @observable
    isOpen = false;

    @observable
    media = null;

    callback

    open = (media, callback) => {
        this.isOpen = true;
        this.media = media;
        this.callback = callback;
    }

    close = () => {
        this.callback = undefined;
        this.media = undefined;
        this.isOpen = false;
    }

}

const mediaDeleteStore = new MediaDeleteStore();

@observer
class MediaDeleteModal extends React.Component {

    render() {
        let store = mediaDeleteStore;
        return (
            <ModalBase
                style={{zIndex: '99999'}}
                centered
                show={store.isOpen}
                onHide={store.close}
                size={'sm'}
            >
                <Modal.Header>
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        <Modal.Title>영상 삭제</Modal.Title>
                        <Button variant={"outline-light"} size={'sm'}
                                onClick={() => {
                                    store.close()
                                }}
                        >
                            <MdClear size={`1rem`}/>
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{color: '#fff'}}>
                        {
                            `${store.media?.name} 삭제 하시겠습니까?`
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        <div>

                        </div>
                        <Button variant="outline-danger"
                                onClick={() => {
                                    axios.post(
                                        `/media/delete`,
                                        store.media
                                    ).then(res => {
                                        notificationStore.notification("삭제 되었습니다.", '성공', NOTIFICATION_TYPE.SUCCESS)
                                        store.callback && store.callback();
                                        store.close();
                                    })
                                }}
                        >
                            삭제
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBase>
        );
    }
}

export {MediaDeleteModal, mediaDeleteStore};
