import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {historyMediaStore, recentlyMediaStore} from "../../store/MediaStore";
import ContentWrap from "../../components/layout/ContentWrap";
import InfiniteScroll from "react-infinite-scroller";
import Media from "../../components/media/Media";
import {Button, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import Styles from './HistoryView.module.scss'
import SortButton from "../../components/media/SortButton";
import {HISTORY, navigate} from "../../helper/HistoryHelper";
import {MagnifyingGlass} from "react-loader-spinner";
import {MdArrowBackIos} from "react-icons/md";
import playListStore from "../../store/PlayListStore";
import {TbPencilStar} from "react-icons/tb";
import moment from "moment/moment";


@observer
class HomeView extends Component {
    constructor(props) {
        super(props);
        this.state={
            keyword : ''
        }

    }

    componentDidMount() {
        let store = historyMediaStore;
        store.scrollRef.current.scrollTo(0, store.scrollTop)
        if(store.isInit){
            store.debounceRefresh();
        }
    }

    componentWillUnmount() {
    }

    render() {
        let store = historyMediaStore;
        return (
            <ContentWrap>
                <div className={Styles.SearchBar}>
                    <Container className={`p-0 d-flex justify-content-between flex-column`}>
                        <div className={Styles.Header}>
                            <div className={Styles.BackButton}
                                 onClick={() => {
                                     HISTORY.goBack();
                                 }}
                            >
                                <MdArrowBackIos color={'#fff'} size={`1.5rem`}/>
                            </div>
                            <div className={Styles.Title}>
                                시청기록
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={`d-flex `}>
                            <div className={`d-flex flex-grow-1 text-nowrap`}>
                                <SortButton id={'history'}
                                            title={'시청순'}
                                            store={store}
                                />
                            </div>
                            <div>
                                <InputGroup>
                                    <FormControl size={'sm'}
                                                 value={this.state.keyword}
                                                 onChange={e => {
                                                     this.setState({keyword: e.target.value});
                                                 }}
                                                 onKeyDown={e => {
                                                     if (e.key === 'Enter') {
                                                         store.searchCondition.set("keyword", this.state.keyword)
                                                         store.debounceFetch(true);
                                                     }
                                                 }}
                                    />
                                    <Button variant={"outline-light"} size={'sm'}
                                            onClick={() => {
                                                store.searchCondition.set("keyword", this.state.keyword)
                                                store.debounceFetch(true);
                                            }}
                                    >
                                        검색
                                    </Button>
                                </InputGroup>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className={Styles.Content}
                     ref={store.scrollRef}
                     onScroll={store.onScrollHandler}
                >
                    <InfiniteScroll
                        loadMore={(page) => {
                            store.debounceFetch(false)
                        }}
                        hasMore={store.files.length < store.count}
                        loader={
                            <div key={"a"}>
                            </div>
                        }
                        useWindow={false}
                        threshold={5}
                    >
                        <Container className={`p-0`}>
                            <Row>
                                {
                                    !store.isFetching && store.files.length < 1 &&
                                    <div className={`align-items-center justify-content-center d-flex p-5`}>
                                        검색 결과가 없습니다.
                                    </div>
                                }
                                {
                                    store.files.map(file => {
                                        return (
                                            <Col sm={12} md={4} xl={3} key={`HOME-${file.id}`}>
                                                <Media file={file}
                                                       onClick={(file)=>{
                                                           navigate(`/main/file/${file?.folder?.id}/${file.id}`)
                                                       }}
                                                       dateLabel={`${moment(file?.history?.created).fromNow()} 시청함`}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                                {
                                    store.isFetching &&
                                    <Col sm={12} md={12} xl={12} className={`d-flex justify-content-center align-items-center p-5`}>
                                        <MagnifyingGlass
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="magnifying-glass-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="magnifying-glass-wrapper"
                                            glassColor="#c0efff"
                                            color="#afafaf"
                                        />

                                    </Col>
                                }
                            </Row>
                        </Container>
                    </InfiniteScroll>
                </div>
            </ContentWrap>
        );
    }
}

HomeView.propTypes = {};

export default HomeView;
