import React from 'react';
import {observer} from 'mobx-react';
import ModalBase from "./ModalBase";
import playListStore from "../../store/PlayListStore";
import Styles from './PlayListModal.module.scss';
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md";

@observer
class PlayListModal extends React.Component {


    render() {
        let store = playListStore;
        return (
            <ModalBase
                style={{zIndex: '99999'}}
                centered
                show={store.show}
                onHide={store.close}
                size={'sm'}
            >
                {
                    store.playLists.map(playList =>
                        <div className={Styles.PlayList}
                             key={`PLAY-LIST-${playList.id}`}
                             onClick={() => {
                                 store.togglePlayList(playList);
                             }}
                        >
                            <div className={Styles.CheckBox}>
                                {
                                    playList?.isHave ?
                                        <MdOutlineCheckBox/>
                                        :
                                        <MdOutlineCheckBoxOutlineBlank color={"var(--bs-secondary)"}/>
                                }
                            </div>
                            <div className={Styles.Name}>
                                {playList.name}
                            </div>
                        </div>
                    )
                }
            </ModalBase>
        )
    }
}

export default PlayListModal;
