import React from 'react';
import {observer} from 'mobx-react';
import Styles from './MobileHeader.module.scss';
import {HISTORY, navigate} from "../../helper/HistoryHelper";
import {MdArrowBackIos, MdThumbUp} from "react-icons/md";
import {IoDice} from "react-icons/io5";
import axios from "axios";
import {Button} from "react-bootstrap";

@observer
class MobileHeader extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {title} = this.props
        return (
            <div className={Styles.MobileHeader}>
                <div className={Styles.Button}
                     onClick={() => {
                         HISTORY.goBack();
                     }}
                >
                    <MdArrowBackIos color={'#fff'} size={`1.5rem`}/>
                </div>
                <div className={Styles.Title}>
                    {title}
                </div>
                <div className={Styles.Button}
                     onClick={() => {
                         axios.post(`/my/random/like`)
                             .then(r =>
                                 navigate(`/main/folder/${r.data}`)
                             )
                     }}
                >
                    <IoDice color={'#fff'} size={`1.5rem`}/>
                </div>
            </div>

        );
    }

}

export default MobileHeader;
