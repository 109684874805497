import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from './AvatarTag.module.scss'
import {Image} from "react-bootstrap";
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import {tagMediaStore} from "../../store/MediaStore";
import {navigate} from "../../helper/HistoryHelper";

@observer
class AvatarTag extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let {tag} = this.props;
        return (
            <div className={Styles.AvatarTag}
                 onClick={()=>{
                     let store = tagMediaStore;
                     store.isInit = true
                     store.clear()
                     navigate(`/main/tag/${tag.id}`)
                 }}
            >
                <div className={Styles.Avatar}>
                    <Image src={`${THUMBNAIL_BASE_URL}/tag/${tag?.id}/${tag?.thumbnailPath}`}/>
                </div>
                <div className={Styles.Name}>
                    {tag?.translateName ? tag?.translateName : tag?.name}
                </div>
            </div>
        );
    }
}

AvatarTag.propTypes = {};

export default AvatarTag;