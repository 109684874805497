import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from './DetailView.module.scss'
import axios from "axios";
import {makeObservable, observable} from "mobx";
import Player from "../../components/media/Player";
import {playerStore} from "../../store/PlayerStore";
import {Button, Container, Image, InputGroup} from "react-bootstrap";
import MobileHeader from "../../components/layout/MobileHeader";
import ContentWrap from "../../components/layout/ContentWrap";
import {MdArrowDropUp, MdOutlinePlaylistAdd, MdOutlinePlaylistPlay, MdThumbDown, MdThumbUp} from "react-icons/md";
import playListStore from "../../store/PlayListStore";
import PlayList from "../../components/media/PlayList";
import {FaPlus, FaUser} from "react-icons/fa";
import Media from "../../components/media/Media";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import AvatarTag from "../../components/tag/AvatarTag";
import {navigate, navigateReplace} from "../../helper/HistoryHelper";
import {historyMediaStore, playListMediaStore, recentlyMediaStore} from "../../store/MediaStore";
import {spriteThumbnailStore} from "../../components/modal/SpriteThumbnailModal";


@observer
class DetailView extends Component {

    constructor(props, context) {
        super(props, context);
        makeObservable(this);
        this.state = {
            isOpen : false
        }
    }

    componentDidMount() {
        window.addEventListener(`keydown`,
            playerStore.handleKeyDown
        );
        window.addEventListener(`keyup`,
            playerStore.handleKeyUp
        );
        window.addEventListener('beforeunload', this.insertHistory);
        // window.addEventListener('unload', 콜백함수);
        let { match:{ params } } = this.props;
        let {folderId, fileId} = params;
        this.load(folderId, fileId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevParams = prevProps.match.params;
        let nextParams = this.props.match.params;
        if((prevParams.folderId !== nextParams.folderId)||(prevParams.fileId !== nextParams.fileId)){
            this.insertHistory()
                .then(r=>this.load(nextParams.folderId, nextParams.fileId));
        }
    }

    componentWillUnmount() {
        window.removeEventListener(`keyup`,
            playerStore.handleKeyUp
        );
        window.removeEventListener(`keydown`,
            playerStore.handleKeyDown
        );
        window.removeEventListener('beforeunload', this.insertHistory);
        this.insertHistory()
        spriteThumbnailStore.close();
        console.log(playerStore)
    }

    load(folderId, fileId){
        let store = this;
        axios.post(`/media/detail/${folderId}`,{})
            .then(res=>{
                let folder = res.data;
                if(folder.files && folder.files.length > 0){
                    let file;
                    if(fileId){
                        file = folder.files.find(file=>file.id === parseInt(fileId))
                    }else{
                        file = folder.files[0];

                    }
                    this.folder = folder;
                    this.files = folder.files;
                    this.file = file
                    playerStore.setFile(file, folder);
                }

            })
            .catch(err=>{
            });
    }

    insertHistory = async() => {
        if(this.file){
            axios.post(
                `/media/history/insert`,
                {
                    ...this.file,
                    history:{
                        playedSeconds : playerStore.playedSeconds
                    }
                }
            ).then(({data})=>{
                [recentlyMediaStore, playListMediaStore, historyMediaStore]
                    .forEach(store=>{
                        if(store.fileMap.has(data.id)){
                            store.fileMap.set(data.id,data)
                        }
                    })
            })
        }
    }

    @observable
    file;

    @observable
    folder;

    @observable
    files = [];

    render() {
        let selectedFile = this.file;
        let files = this.files;
        let folder = this.folder;
        let actors = selectedFile?.tags?.filter(tag=>tag?.type === 'ACTOR') || [];
        return (
            <ContentWrap>
                <MobileHeader title={selectedFile?.name}/>
                <div className={Styles.DetailView}>
                    <Container className={Styles.DetailContainer}>
                        <div className={Styles.PlayerContainer}>
                            {
                                selectedFile &&
                                <Player url={'/api/media/'+selectedFile?.realPath}/>
                            }
                        </div>
                        <div className={Styles.DetailInfo}>

                            {
                                actors.length > 0 &&
                                <div className={Styles.ActorList}>
                                    <div className={`d-flex align-items-center`}>
                                        <FaUser className={`me-2`}/> 배우정보
                                    </div>
                                    <div className={Styles.List}>
                                        {
                                            actors.map(tag=>
                                                <AvatarTag tag={tag}/>
                                            )
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                        <div>

                        </div>
                        <div className={`${Styles.ListWrap} ${this.state.isOpen ? Styles.Open : ''}`}>
                            {
                                files.filter(file=>file?.id != selectedFile.id).length > 0 &&
                                    <>
                                        <div className={Styles.Header}
                                             onClick={()=>{
                                                 this.setState({isOpen:!this.state.isOpen})
                                             }}
                                        >
                                            <div className={Styles.Title}>
                                                <MdOutlinePlaylistPlay size={`1.5rem`} className={`me-2`}/> 관련 영상
                                            </div>
                                            <div className={Styles.Link}>
                                                {
                                                    this.state.isOpen ?
                                                        <IoIosArrowDown size={`1.5rem`}/>
                                                        :
                                                        <IoIosArrowUp size={`1.5rem`}/>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${Styles.List} `}>
                                            {
                                                files.filter(file=>file?.id != selectedFile.id).map(file=>
                                                    <div style={{minWidth:300, maxWidth:300}} className={`pe-3 mt-3`} key={`RELATION-${file.id}`}>
                                                        <Media file={{...file, folder}}
                                                               onClick={(file)=>{
                                                                   navigateReplace(`/main/file/${file?.folder?.id}/${file.id}`)
                                                               }}
                                                        />
                                                    </div>
                                                )

                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </Container>
                </div>
            </ContentWrap>
        );
    }
}

export default DetailView;
