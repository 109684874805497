import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Styles from './StatusBar.module.scss';
import {RxCaretSort} from "react-icons/rx";
import {Button, Dropdown, Image} from "react-bootstrap";
import appStore from "../../store/AppStore";
import axios from "axios";
import {CloseCircle, MinusCirlce, RecordCircle, UserSquare} from "iconsax-react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        className={Styles.Toggle}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

@observer
class StatusBar extends React.Component {

    render() {
        return (
            <div className={Styles.StatusBar}>
                <div className={`d-flex align-items-center`}>
                    <div className={Styles.Logo}>
                        <Image src={`/asset/img/logo.png`} />
                    </div>
                </div>
                <div className={`d-flex`}>
                    <div className={Styles.Profile}>
                        <span className={Styles.Name}>{appStore.name}</span>
                    </div>
                    <div className={`me-2`}>
                        <Button variant={`secondary`} onClick={e => appStore.fetchLogout()} size={'sm'}>로그아웃</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatusBar;
