import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'react-bootstrap';

@observer
class ModalBase extends React.Component {


    render() {
        let {fullscreen = false, style = {}, show, onHide, size, backdrop = true, keyboard = true, enforceFocus = true, scrollable = true} = this.props;
        return (
            <Modal
                style={{ zIndex: '99999', ...style}}
                enforceFocus={enforceFocus}
                centered
                show={show}
                onHide={() => onHide && onHide()}
                size={size}
                backdrop={backdrop}
                keyboard={keyboard}
                fullscreen={fullscreen}
                onEscapeKeyDown={(e)=>{
                }}
                scrollable={scrollable}
            >
                {this.props.children}
            </Modal>
        )
    }
}

export default ModalBase;
