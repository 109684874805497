import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
    apiKey: "AIzaSyAcgacdANmDy82dEK0btWQZogZnTj-ifM8",
    authDomain: "skt-t-diag.firebaseapp.com",
    projectId: "skt-t-diag",
    storageBucket: "skt-t-diag.appspot.com",
    messagingSenderId: "807244583935",
    appId: "1:807244583935:web:54c98ba7bf2e49b04b0dca",
    measurementId: "G-GZQY3Q3VEE"
};

export let messaging = undefined;
export let requestForToken = undefined;
export let onMessageListener = undefined;



try {
    initializeApp(firebaseConfig);
    if(firebase.messaging.isSupported()){
        messaging = getMessaging();
        requestForToken = async () => {
            return getToken(messaging, { vapidKey: 'BHNruB8duRPteNfM2tBwjLGjC2AklK6PB5t3krYEhybZPpcglTe_3Vo2g_LJe_3FIBi6XerZY4qDWWtNzWIZGGM' });
        };
        onMessageListener = () =>{
            return new Promise((resolve) => {
                onMessage(messaging, (payload) => {
                    resolve(payload);
                });
            });
        }
    }
}catch (e) {

}

