import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {recentlyMediaStore} from "../../store/MediaStore";
import ContentWrap from "../../components/layout/ContentWrap";
import InfiniteScroll from "react-infinite-scroller";
import Media from "../../components/media/Media";
import {Button, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import Styles from './ActorView.module.scss'
import Sort from "../../store/Sort";
import {isMobile} from "react-device-detect";
import {navigate, navigateReplace} from "../../helper/HistoryHelper";
import {Skeleton} from "antd";
import {MagnifyingGlass} from "react-loader-spinner";
import SortButton from "../../components/media/SortButton";
import {actorTagStore} from "../../store/TagStore";
import Actor from "../../components/actor/Actor";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {MdArrowRight, MdRemoveRedEye} from "react-icons/md";
import {FaArrowRight, FaUser} from "react-icons/fa";
import {IoArrowForward} from "react-icons/io5";
import {IoIosArrowForward} from "react-icons/io";

@observer
class CategoryView extends Component {

    constructor(props) {
        super(props);
        this.state={
            keyword : ''
        }

    }

    componentDidMount() {
        let store = actorTagStore;
        setTimeout(()=>{
            store.scrollRef.current.scrollTo(0, store.scrollTop)
        }, 1000)
        if(store.isInit){
            store.debounceRefresh();
        }
    }

    componentWillUnmount() {
    }

    renderLink({title, link}){
        return (
            <div className={`d-flex flex-grow-1`}>
                <Button variant={"outline-light"} className={`flex-grow-1`}
                        onClick={()=>{
                            navigate(link)
                        }}
                >
                    <div className={`d-flex justify-content-between`}>
                        {title}
                        <div className={`d-flex align-items-center`}>
                            <IoIosArrowForward/>
                        </div>
                    </div>
                </Button>
            </div>
        )
    }

    render() {
        let store = actorTagStore;
        return (
            <ContentWrap>

                <div className={Styles.Content}
                     ref={store.scrollRef}
                     onScroll={store.onScrollHandler}
                >
                    <div className={`pb-3`}>
                        <h2>바로가기</h2>
                    </div>
                    <div className={`d-flex flex-wrap gap-3`}>

                        {this.renderLink({
                            title:
                                <div className={`d-flex align-items-center`}>
                                    <MdRemoveRedEye className={`me-2`}/> 미시청
                                </div>,
                            link: '/main/unwatched'
                        })}
                        {this.renderLink({
                            title:
                                <div className={`d-flex align-items-center`}>
                                    <FaUser className={`me-2`}/> 배우
                                </div>,
                            link: '/main/actor'
                        })}

                    </div>
                </div>
            </ContentWrap>
        );
    }
}

CategoryView.propTypes = {};

export default CategoryView;
