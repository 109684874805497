import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {recentlyMediaStore} from "../../store/MediaStore";
import ContentWrap from "../../components/layout/ContentWrap";
import InfiniteScroll from "react-infinite-scroller";
import Media from "../../components/media/Media";
import {Button, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import Styles from './RecentlyView.module.scss'
import {navigate, navigateReplace} from "../../helper/HistoryHelper";
import {MagnifyingGlass} from "react-loader-spinner";
import SortButton from "../../components/media/SortButton";

@observer
class RecentlyView extends Component {

    constructor(props) {
        super(props);
        this.state={
            keyword : ''
        }

    }

    componentDidMount() {
        let store = recentlyMediaStore;
        store.scrollRef.current.scrollTo(0, store.scrollTop)
        if(store.isInit){
            store.debounceRefresh();
        }
    }

    componentWillUnmount() {
    }

    render() {
        let store = recentlyMediaStore;
        return (
            <ContentWrap>
                <div className={Styles.SearchBar}>
                    <Container className={`p-0 d-flex justify-content-between`}>
                        <div className={`d-flex flex-grow-1 text-nowrap`}>
                            <SortButton id={'id'}
                                        title={'등록순'}
                                        store={store}
                            />
                            <SortButton id={'view'}
                                        title={'조회순'}
                                        store={store}
                            />
                            <SortButton id={'like'}
                                        title={'인기순'}
                                        store={store}
                            />
                        </div>
                        <div>
                            <InputGroup>
                                <FormControl size={'sm'}
                                             value={this.state.keyword}
                                             onChange={e=>{
                                                 this.setState({keyword:e.target.value});
                                             }}
                                             onKeyDown={e=>{
                                                 if(e.key === 'Enter') {
                                                     store.searchCondition.set("keyword", this.state.keyword)
                                                     store.debounceFetch(true);
                                                 }
                                             }}
                                />
                                <Button variant={"outline-light"} size={'sm'}
                                        onClick={()=>{
                                            store.searchCondition.set("keyword", this.state.keyword)
                                            store.debounceFetch(true);
                                        }}
                                >
                                    검색
                                </Button>
                            </InputGroup>
                        </div>
                    </Container>
                </div>
                <div className={Styles.Content}
                     ref={store.scrollRef}
                     onScroll={store.onScrollHandler}
                >
                    <InfiniteScroll
                        loadMore={(page) => {
                            store.debounceFetch(false)
                        }}
                        hasMore={store.files.length < store.count}
                        loader={
                            <div key={"a"}>
                            </div>
                        }
                        useWindow={false}
                        threshold={5}
                    >
                        <Container className={`p-0`}>
                            <Row>
                                {
                                    !store.isFetching && store.files.length < 1 &&
                                        <div className={`align-items-center justify-content-center d-flex p-5`}>
                                            검색 결과가 없습니다.
                                        </div>
                                }
                                {
                                    store.files.map(file => {
                                        return (
                                            <Col sm={12} md={4} xl={3} key={`HOME-${file.id}`}>
                                                <Media file={file}
                                                       onClick={(file)=>{
                                                           navigate(`/main/file/${file?.folder?.id}/${file.id}`)
                                                       }}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                                {
                                    store.isFetching &&
                                        <Col sm={12} md={12} xl={12} className={`d-flex justify-content-center align-items-center p-5`}>
                                            <MagnifyingGlass
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="magnifying-glass-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="magnifying-glass-wrapper"
                                                glassColor="#c0efff"
                                                color="#afafaf"
                                            />

                                        </Col>
                                }
                            </Row>
                        </Container>
                    </InfiniteScroll>
                </div>
            </ContentWrap>
        );
    }
}

RecentlyView.propTypes = {};

export default RecentlyView;
