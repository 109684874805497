import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import Styles from './Actor.module.scss'
import {Card, Image} from 'react-bootstrap';
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import {Img} from "react-image";
import moment from "moment";
import {MdVideoFile, MdVideoLibrary} from "react-icons/md";
import {playListMediaStore, tagMediaStore} from "../../store/MediaStore";
import {navigate} from "../../helper/HistoryHelper";
import axios from "axios";

@observer
class Actor extends Component {
    render() {
        let { store, tag, onClick, dateLabel = `${moment(tag?.created).fromNow()} 등록됨`} = this.props;
        let src = []
        if(tag.thumbnailPath)
            src.push(`${THUMBNAIL_BASE_URL}/tag/${tag?.id}/${tag.thumbnailPath}`);
        src.push(`/asset/img/no_actor_thumbnail.png`);
        return (
            <Card className={Styles.Actor}

            >
                <div className={Styles.Image}
                     onDragEnter={e => {
                         e.preventDefault();
                     }}
                     onDragOver={e => {
                         e.stopPropagation();
                         e.preventDefault();
                     }}
                     onDrop={e => {
                         e.preventDefault();
                         e.stopPropagation()

                         if (e.dataTransfer.files.length > 0) {
                             let formData = new FormData();
                             for (const [key, value] of Object.entries(tag)) {
                                 if (value)
                                     formData.append(key, value);
                             }
                             formData.append('file', e.dataTransfer.files[0])
                             axios.post(`/tag/thumbnail/insert`,
                                 formData,
                                 {responseType: "json"}
                             ).then(({data}) => {
                                 store.tagMap.set(tag.id, data)
                             });
                         }

                     }}
                >
                    <Img src={src}
                         onClick={()=>{
                             let store = tagMediaStore;
                             store.isInit = true
                             store.clear()
                             navigate(`/main/tag/${tag.id}`)
                         }}
                    />
                    <div className={Styles.Info}>
                        <MdVideoLibrary size={`1.5rem`} className={`me-1`}/>
                        <div className={Styles.Dot}>
                            {tag.mediaCount}
                        </div>
                    </div>
                </div>
                <Card.Body>
                    <Card.Title className={Styles.TranslateName}>{tag.translateName}</Card.Title>
                    <Card.Text className={Styles.Name}>
                        {tag.name}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
}

Actor.propTypes = {};

export default Actor;