import React, {Component} from 'react';
import {
    Button, Col, Container, Form,
    Image, Row,
} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {FormStore, Input, Validation} from '../components/validation';
import appStore from "../store/AppStore";
import {Redirect} from "react-router-dom";
import Styles from './LoginView.module.scss'

const loginForm = new FormStore();
const store = loginForm;

@observer
class LoginView extends Component {

    componentDidMount() {
        let userInfo = window.localStorage.getItem("cookie");
        if(userInfo){
            let values = JSON.parse(window.localStorage.getItem("cookie"));
            if(values.isSave && !appStore.isLogin){
                appStore.fetchLogin({
                    form: values,
                    fail: ()=>{
                        window.localStorage.setItem("cookie", '');
                        loginForm.clear()
                    }
                }).then(r=>{
                })
            }
        }
    }

    doLogin(){
        appStore.fetchLogin({
            form: loginForm.values,
            fail: ()=>{
                window.localStorage.setItem("cookie", '');
                loginForm.clear()
            }
        }).then(r=>{
            if(store.valueMap.get("isSave") === 'true'){
                window.localStorage.setItem("cookie", JSON.stringify(loginForm.values));
            }else{
                window.localStorage.setItem("cookie", '');
            }
            loginForm.clear()
        })
    }

    render() {
        return (
            <Container className={Styles.Form}>
                {appStore.isLogin && <Redirect to="/main" />}
                <div className={Styles.Logo}>
                    <Image src={`/asset/img/logo.png`} style={{width:'100%'}}/>
                </div>
                <div className={`d-flex flex-column w-100`}>
                    <div>
                        <Input store={store}
                               label={`ID`}
                               name={`email`}
                               placeholder={`아이디를 입력`}
                               validations={[
                                   new Validation(/^.+$/, `아이디를 입력하세요.`)
                               ]}
                               validOnBlur
                        />
                    </div>
                    <div>
                        <Input store={store}
                               label={`PASSWORD`}
                               name={`password`}
                               placeholder={`비밀번호를 입력`}
                               type={`password`}
                               validations={[
                                   new Validation(/^.+$/, `비밀번호를 입력하세요.`)
                               ]}
                               onKeyDown={(e)=>{
                                   if(e.keyCode === 13){
                                       this.doLogin()
                                   }
                               }}
                               validOnBlur
                        />
                    </div>
                    <div>
                        <Row>
                            <Col className={`d-flex justify-content-end align-items-center`}>
                                <span className={`pe-2`}>
                                    자동 로그인
                                </span>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="switch"
                                                defaultChecked={store.valueMap.get('isSave')}
                                                onChange={e=>{
                                                    store.setValue("isSave", e.target.checked.toString());
                                                }

                                                }/>
                                </Form.Group>
                            </Col>
                            <Col className={`d-flex flex-column`}>
                                <Button disabled={!store.valid}
                                        variant="primary"
                                        type="button"
                                        onClick={() =>
                                            this.doLogin()
                                        }
                                >
                                    로그인
                                </Button>

                            </Col>
                        </Row>
                    </div>
                </div>

            </Container>
        );
    }
}

LoginView.propTypes = {};

export default LoginView;
