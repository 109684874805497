import * as moment from 'moment';
import {MS_HOUR, MS_MINUTE, MS_SECOND} from "../const/AppConst";

class CommonHelper {
    static groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    static range(a, b) {
        let result = [];
        for (let i = a; i < b; i++) {
            result.push(i);
        }
        return result;
    }

    static findKeys(m, ...keys) {
        let result = {};
        m.forEach((v, k) => keys.includes(k) && (result[k] = v));
        return result;
    }

    static omit(obj, ...keys) {
        let result = Object.assign({}, obj);
        keys.forEach(key => delete result[key]);
        return result;
    }

    static clearValue(m) {
        m.keys().forEach(k => m.set(k, ''));
    }

    static mapToObject(m) {
        let obj = {};
        m.forEach((v, k) => {
            obj[k] = v;
        });
        return obj;
    };


    static copyPropertiesToMap(obj, m) {
        Object.entries(obj).forEach(
            ([k, v]) => m.set(k, v)
        );
    }

    static objectToMap(obj){
        let m = new Map();
        CommonHelper.copyPropertiesToMap(obj, m);
        return m;
    }

    static urlHostname(data) {
        let a = document.createElement('a');
        a.href = data;
        return a.hostname;
    }

    static yearRange(from, to){
        let diff = to - from;
        let diffs = CommonHelper.range(0, diff + 1);
        return diffs.map( d => moment(from, 'YYYY').add(d , 'year').format('YYYY'))
    }

    static arrayMove(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    };

    static getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static millisecondConvertTime = time => {
        let hour = Math.floor((time)/MS_HOUR);
        let minute = Math.floor((time-(hour*MS_HOUR))/MS_MINUTE);
        let second = Math.floor((time-(hour*MS_HOUR + minute*MS_MINUTE))/MS_SECOND);
        return `${hour ? `${hour}:`:''}${(minute<10) ? '0':''}${minute}:${(second<10) ? '0':''}${second}`;
    }

    static calculateThumbnailLeft(percent) {
        if(percent < 10){
            return 10;
        }else if(percent > 90){
            return 90;
        }else{
            return percent;
        }
    }

    static calculateThumbnailPosition(second) {
        let count = Math.floor(second / 5);
        let countString = count.toString();
        let x = parseInt(countString[countString.length - 1]);
        let y = Math.floor(count/10);
        return `-${x*160}px -${y*90}px`;
    }
}

export default CommonHelper;
