import React from 'react';
import {observer} from 'mobx-react';
import Styles from './SideBar.module.scss';
import {
    MdHome,
    MdLocalLibrary,
    MdOutlineAccessTime,
    MdOutlinePlaylistPlay,
    MdRemoveRedEye, MdSort,
    MdVideoLibrary
} from "react-icons/md";
import {Button} from "react-bootstrap";
import {navigate} from "../../helper/HistoryHelper";
import playListStore from "../../store/PlayListStore";
import {playListMediaStore} from "../../store/MediaStore";
import {FaUser} from "react-icons/fa";

@observer
class SideBar extends React.Component {

    render() {
        return (
            <div className={Styles.SideBar}>

                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/home`)
                        }}
                >
                    <MdHome size={`1.5rem`}/>
                    홈
                </Button>

                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/actor`)
                        }}
                >
                    <FaUser size={`1.5rem`}/>
                    배우
                </Button>

                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/recently`)
                        }}
                >
                    <MdSort size={`1.5rem`}/>
                    등록순
                </Button>

                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/storage`)
                        }}
                >
                    <MdVideoLibrary size={`1.5rem`}/>
                    보관함
                </Button>

                <Button className={Styles.Menu} variant={"outline-secondary"}
                        onClick={()=>{
                            navigate(`/main/history`)
                        }}
                >
                    <MdOutlineAccessTime size={`1.5rem`}/>
                    시청{`\n`}기록
                </Button>
                {
                    playListStore.playLists.map(playList=>
                        <Button className={Styles.Menu} variant={"outline-secondary"}
                                onClick={()=>{
                                    let store = playListMediaStore;
                                    store.isInit = true
                                    store.clear()
                                    navigate(`/main/play/list/${playList?.id}`)
                                }}
                        >
                            <MdOutlinePlaylistPlay size={`2rem`}/>
                            {playList?.name}
                        </Button>
                    )
                }
            </div>
        );
    }
}

export default SideBar;
