import React from 'react';
import {observer} from 'mobx-react';
import ModalBase from "./ModalBase";
import playListStore from "../../store/PlayListStore";
import Styles from './PlayListModal.module.scss';
import {MdClear, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdRemove} from "react-icons/md";
import {Button, FormControl, Modal} from "react-bootstrap";
import axios from "axios";
import notificationStore, {NOTIFICATION_TYPE} from "../../store/NotificationStore";
import {goBack} from "../../helper/HistoryHelper";


@observer
class PlayListModifyModal extends React.Component {


    render() {
        let store = playListStore;
        return (
            <ModalBase
                style={{zIndex: '99999'}}
                centered
                backdrop={'static'}
                show={store.showModify}
                onHide={store.closeModify}
                size={'sm'}
            >
                <Modal.Header>
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        <Modal.Title>재생목록 수정</Modal.Title>
                        <Button variant={"outline-light"} size={'sm'}
                                onClick={()=>{
                                    store.closeModify()
                                }}
                        >
                            <MdClear size={`1rem`}/>
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <FormControl size={`sm`}
                                 value={store?.selectPlayList?.name || ''}
                                 onChange={e=>{
                                     store.selectPlayList.name = e.target.value;
                                 }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        <div>
                            {
                                !store.selectPlayList?.isDefault &&
                                    <Button variant="outline-danger"
                                            onClick={()=>{
                                                axios.post(
                                                    `/play/list/delete`,
                                                    store.selectPlayList
                                                ).then(res=>{
                                                    notificationStore.notification("삭제 되었습니다.", '성공', NOTIFICATION_TYPE.SUCCESS)
                                                    goBack();
                                                    store.closeModify();
                                                })
                                            }}
                                    >
                                        삭제
                                    </Button>
                            }
                        </div>
                        <Button variant="outline-primary"
                                onClick={()=>{
                                    axios.post(
                                        `/play/list/update`,
                                        store.selectPlayList
                                    ).then(res=>{
                                        notificationStore.notification("저장 되었습니다.", '성공', NOTIFICATION_TYPE.SUCCESS)
                                        store.saveCallback && store.saveCallback();
                                        store.closeModify();
                                    })
                                }}
                        >
                            저장
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBase>
        )
    }
}

export default PlayListModifyModal;
