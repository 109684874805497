import React from 'react';
import {computed, makeObservable, observable, reaction, values} from "mobx";
import axios from "axios";
import CommonHelper from "../helper/CommonHelper";
import Sort from "./Sort";
import {debounce} from "lodash";

class MediaStore {

    constructor({url = `/list`, rowsPerPage = 40, sort = new Sort('id', Sort.SORT_DIRECTION.DESC), defaultSearchCondition = new Map()}) {
        makeObservable(this);
        this.url = url;
        this.rowsPerPage = rowsPerPage;
        this.sort = sort;
        this.searchCondition = defaultSearchCondition;
    }

    @observable
    isInit = true;

    @observable
    url;

    @observable page = 1;

    @observable isFetching = false;

    /**
     * 정렬 컬럼
     * @type Sort
     */
    @observable sort;

    /**
     * 현재 검색 조건
     * @type {Map}
     */
    @observable searchCondition = new Map();

    /**
     * 페이징 블럭 사이즈
     * @type {number}
     */
    paginationSize;

    /**
     * 페이지당 행 수
     * @type {number}
     */
    rowsPerPage;

    scrollRef = React.createRef();

    @observable
    scrollTop = 0;

    onScrollHandler = e => {
        let store = this;
        store.scrollTop = e.target.scrollTop;
    }

    @computed
    get searchConditionWithPage() {
        let m = CommonHelper.mapToObject(this.searchCondition);
        m.pagingOption = {
            page: this.page,
            rowsPerPage: this.rowsPerPage,
            sort: this.parameterizeSort
        };
        return m;
    }

    @computed
    get parameterizeSort() {
        return this.sort.toParam()
    }

    debounceFetch = debounce(reset => {
        if(reset) {
            this.clear()
            this.page = 1
        }else{
            this.page += 1;
        }
        this.fetch(reset)
    },500
    )

    debounceRefresh = debounce(() => {
        this.clear()
        this.fetch(true)
        },500
    )

    fetch = async (reset = false) => {
        let store = this;
        if (store.isFetching)
            return;
        store.isFetching = true;
        let {data: {items, itemCount}} = await axios.post(`/media${this.url}`, this.searchConditionWithPage)
        let originFiles = store.files
        store.fileMap.replace(originFiles.concat(items).map(media => [media.id, media]))
        store.count = itemCount;
        store.isFetching = false;
        store.isInit = false;
        return originFiles.concat(items).map(media => [media.id, media]);
    }

    @computed
    get isMore() {
        let store = this;
        return store.files.length < store.count;
    }

    @observable
    fileMap = new Map();

    @computed
    get files() {
        return values(this.fileMap) || [];
    }

    @observable
    count = 0;

    clear() {
        this.count = 0;
        this.fileMap.clear();
    }

    @computed
    get lastFile(){
        return this.files.length > 0 ? this.files[0] : null;
    }

    @observable
    target;

}


const recentlyMediaStore = new MediaStore({
    url:'/list'
});

const historyMediaStore = new MediaStore({
    url:'/history/list',
    sort: new Sort('history', Sort.SORT_DIRECTION.DESC)
});

const playListMediaStore = new MediaStore({
    url:'/list',
});

const unwatchedMediaStore = new MediaStore({
    url:'/list',
    sort: new Sort('like', Sort.SORT_DIRECTION.DESC),
    defaultSearchCondition : new Map([[`isUnwatched`, true]])
})

const tagMediaStore = new MediaStore({
    url:'/list',
});

export {recentlyMediaStore, historyMediaStore, playListMediaStore, unwatchedMediaStore, tagMediaStore};
