import React from 'react';
import ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from 'react-router-dom';
import appStore from '../store/AppStore';
import {HISTORY} from "../helper/HistoryHelper";
import {onMessageListener, requestForToken} from "../firebase";
import axios from "axios";
import {USER_TYPE} from "../const/AppConst";
import Styles from './MainViewFrame.module.scss'
import RecentlyView from "./media/RecentlyView";
import MobileNavBar from "../components/layout/MobileNavBar";
import StorageView from "./media/StorageView";
import DetailView from "./media/DetailView";
import StatusBar from "../components/layout/StatusBar";
import SideBar from "../components/layout/SideBar";
import HistoryView from "./media/HistoryView";
import PlayListView from "./media/PlayListView";
import PlayListModal from "../components/modal/PlayListModal";

import PlayListModifyModal from "../components/modal/PlayListModifyModal";
import ActorView from "./tag/ActorView";
import {MediaDeleteModal} from "../components/modal/MediaDeleteModal";
import UnwatchedView from "./media/UnwatchedView";
import TagView from "./media/TagView";
import CategoryView from "./tag/CategoryView";
import SpriteThumbnailModal from "../components/modal/SpriteThumbnailModal";


const modalRoot = document.getElementById('modal');
const DragLayerPortal = ({ children }) => ReactDOM.createPortal(children, modalRoot);

@observer
class MainViewFrame extends React.Component {

    componentDidMount() {
        let store = this;
        if(appStore.isLogin && appStore.type == USER_TYPE.AGENT){
            requestForToken && requestForToken()
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        axios.post(
                            '/user/registration-push-token',
                            {
                                token:currentToken,
                                type:'WEB'
                            }
                        );
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            onMessageListener && onMessageListener()
                .then((payload) => {
                })
                .catch((err) => console.log('failed: ', err));
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(appStore.currentPath != HISTORY.location.pathname){
            appStore.currentPath = HISTORY.location.pathname;
        }

    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className={`d-flex flex-column flex-default flex-column`} >
                {appStore.isSessionChecked && !appStore.isLogin && <Redirect to={`/`}/>}
                <StatusBar/>
                <div className="d-flex flex-default flex-row">
                    <SideBar/>
                    <div className={`d-flex flex-column flex-default justify-content-center ${Styles.Content}`}>
                        <Switch>
                            <Route path="/main/folder/:folderId" component={DetailView}/>
                            <Route path="/main/file/:folderId/:fileId" component={DetailView}/>
                            <Route path="/main/storage" component={StorageView}/>
                            <Route path="/main/home" component={UnwatchedView}/>
                            <Route path="/main/recently" component={RecentlyView}/>
                            <Route path="/main/play/list/:id" component={PlayListView}/>
                            <Route path="/main/tag/:id" component={TagView}/>
                            <Route path="/main/history" component={HistoryView}/>
                            <Route path="/main/actor" component={ActorView}/>
                            <Route path="/main/category" component={CategoryView}/>
                            <Redirect exact from="/main" to="/main/home"/>
                        </Switch>
                        <MobileNavBar/>
                    </div>
                </div>
                <PlayListModal/>
                <PlayListModifyModal/>
                <MediaDeleteModal/>
                <SpriteThumbnailModal/>
            </div>
        );

    }
}

export default MainViewFrame;
