import axios from 'axios';
import React from 'react';
import {toast} from "react-toastify";
import {navigate} from "../helper/HistoryHelper";
import appStore from "../store/AppStore";
import {NotificationManager} from "react-notifications";

export const rootStyles = getComputedStyle(document.querySelector(':root'));
export const STATS_COLOR = [
    rootStyles.getPropertyValue(`--bs-warning`),
    rootStyles.getPropertyValue(`--bs-primary`),
    rootStyles.getPropertyValue(`--bs-success`),
    rootStyles.getPropertyValue(`--bs-danger`),
    rootStyles.getPropertyValue(`--bs-info`),
]

export const ENCODE_BASE_URL = process.env.REACT_APP_ENCODE_CONTEXT_PATH;

export const APP_API_PATH = window.__RUNTIME_CONFIG__.REACT_APP_API_PATH;
export const APP_UPLOAD_PATH = window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_PATH;
export const APP_CONTEXT_PATH = window.__RUNTIME_CONFIG__.REACT_APP_CONTEXT_PATH;
export const CHAT_WS_URL = window.__RUNTIME_CONFIG__.REACT_CHAT_WS_URL;

export const THUMBNAIL_BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_THUMBNAIL_BASE_URL;

axios.defaults.baseURL = APP_API_PATH+APP_CONTEXT_PATH;
axios.defaults.withCredentials = true;
axios.defaults.responseType = 'json';
axios.interceptors.response.use((response) => response, (error) => {
    console.log(error)
    let response = error.response;
    if(!response || !response.data) {
        NotificationManager.error(error.message);
        throw error;
    }
    NotificationManager.error(response.data?.message);
    if(response.status === 401){
        appStore.isLogin = false;
        navigate("/login")
    }
});

/**
 * URL 메뉴 그룹 추출 정규식
 * @type {RegExp}
 */
export const MENU_GROUP_RE = /\/main\/([^\/]+)(?=\/)?/;


export const USER_TYPE = {
    SUPER : "SUPER",
    AGENT : "AGENT",
}


export const MS_HOUR = 3600;
export const MS_MINUTE = 60;
export const MS_SECOND = 1;
