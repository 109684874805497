import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Button} from "react-bootstrap";
import {MdAdd, MdOutlinePlaylistPlay, MdOutlinePlusOne} from "react-icons/md";
import Styles from "./PlayList.module.scss";
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import {Img} from "react-image";
import CommonHelper from "../../helper/CommonHelper";
import {navigate} from "../../helper/HistoryHelper";
import {playListMediaStore} from "../../store/MediaStore";

@observer
class PlayList extends Component {

    render() {
        let { playList } = this.props;
        let { count, lastMedia } = playList;
        let src= [];
        if(count > 0){
            src.push(`${THUMBNAIL_BASE_URL}/${lastMedia.thumbnailPath}`)
        }
        src.push(`/asset/img/no_thumbnail.png`);

        return (
            <div className={Styles.PlayList}
                 onClick={()=>{
                     let store = playListMediaStore;
                     store.isInit = true
                     store.clear()
                     navigate(`/main/play/list/${playList.id}`)
                 }}
            >
                <div className={Styles.ThumbnailContainer}>
                    <div className={Styles.BackImage}>
                        등록된 영상이 없습니다.
                    </div>
                    {
                        playList.count > 0 &&
                            <div className={Styles.Thumbnail}>
                                <Img src={src}/>
                                <div className={Styles.Cover}>
                                    <div>

                                    </div>
                                    <div>

                                    </div>
                                    <div className={Styles.Bottom}>
                                        <div className={Styles.Info}>
                                            <div className={Styles.Count}>
                                                <MdOutlinePlaylistPlay size={`1.2rem`} className={`me-2`}/>{count.toLocaleString()}개
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {
                    playList?.name
                }

            </div>
        );
    }
}

PlayList.propTypes = {};

export default PlayList;
