
import {computed, makeObservable, observable, reaction, runInAction, values} from 'mobx';
import React, {createRef} from "react";
import fscreen from "fscreen";
import * as moment from 'moment';
import axios from "axios";
import {toast} from "react-toastify";
import {FiVolume, FiVolume1, FiVolume2, FiVolumeX} from "react-icons/fi";
import notificationStore from "./NotificationStore";

class PlayerStore {


    constructor() {
        let store = this;
        makeObservable(this);
        reaction(
            ()=>store.isLoaded,
            isLoaded=>{
                if(isLoaded)
                    store.inputEl.current.seekTo(store.playedSeconds)
            }
        )

    }

    seekBarRef = React.createRef();

    volumeBarRef;

    @observable
    offsetX;

    @observable
    offsetWidth;

    @computed
    get left(){
        let store = this;
        if(store.offsetX < 120){
            return 120;
        }else if(store.offsetX > store.offsetWidth - 120){
            return store.offsetWidth - 120;
        }else{
            return store.offsetX
        }
    }

    @computed
    get thumbnailNumber(){
        let store = this;
        let value = store.offsetX / store.offsetWidth;
        if(value >= 1){
            value = 0.9999;
        }else if(value <= 0){
            value = 0;
        }
        let number = parseInt(value * store.duration / 5) + 2
        // "2"
        return String(number).padStart(10,"0");
    }

    @observable
    isLoaded = false;

    @observable
    isPlay = true;

    @observable
    isPlayPause = false;

    @observable
    isPause = false;

    @observable
    isPip = false;

    @observable
    volume = parseFloat(window.localStorage.getItem("volume")) || 0.50;

    @observable
    isVolumeUp = false;

    @observable
    isVolumeDown = false;

    @observable
    isMute = true

    @observable
    isControlsHide = false;

    @observable
    timer = null;

    @observable
    duration = 0;

    @observable
    playedSeconds = 0;

    @observable
    played = 0;

    @observable
    nextPlayed = 0;

    @observable
    loadedSeconds = 0;

    @observable
    loaded = 0;

    @observable
    seeking = false;

    @observable
    isRewind = false;

    @observable
    isForward = false;

    @observable
    seekingTimer = null;

    @observable
    isBuffered = true;

    @observable
    fileMap = new Map();

    get isUseTrack(){
        return document.querySelector('track')?.track?.mode != 'hidden' ? true : false;
    }

    @computed
    get files(){
        return values(this.fileMap);
    }

    @observable
    selectedFileId;

    @computed
    get selectedFile(){
        return this.fileMap.get(this.selectedFileId);
    }

    @observable
    isFullScreeMode = false;

    inputEl = createRef(null);

    controls = createRef(null);

    playerRef = createRef(null);

    forwardTimeout;

    rewindTimeout;

    volumeTimeout;

    playTimeout;

    reset = () => {
        let store = this;
        store.isLoaded = false;
        store.played = 0;
        store.isPip = false;
        store.isControlsHide = false;
        store.timer = null;
        store.playedDuration = 0;
        store.playedSeconds = 0;
        store.seeking = false;
        store.seekingTimer = null;
        store.isFullScreeMode = false;
    }

    setFile = (file, folder) => {
        let store = this;
        store.isLoaded = false;
        store.fileMap.set(
            file.id,
            {
                ...file,
                folder,
                history: {
                    ...file.history,
                    created: moment().format()
                }
            });
        store.selectedFileId = file.id;
        let history = file?.history;
        let playedSeconds;
        let duration;
        if (history) {
            playedSeconds = history?.playedSeconds;
        } else {
            playedSeconds = 0;
        }
        duration = file?.duration;

        store.duration = duration;
        store.playedSeconds = playedSeconds;
    }

    /**
     * 시간을 변경하는 부분
     * @param value
     */
    changeSeek = value => {
        let store = this;
        store.playedSeconds = value;
        store.inputEl.current.seekTo(value);
    }


    handleSeekBarChange = value => {
        let store = this;
        store.changeSeek(value);
    }

    /**
     * 좌우 방향키로 시간 변경 후 처리
     */
    handleSeekKeyUp = () => {
        this.seeking = false;
        this.isPause = false;
    }

    /**
     * 좌우 방향키로 시간 변경 처리
     * @param changePlayedSeconds
     */
    handleSeekKeyDown = changePlayedSeconds => {
        let store = this;
        store.seeking = true;
        store.isPause = true;
        if(changePlayedSeconds > 0){
            store.isForward = true;
            if(store.forwardTimeout)
                clearTimeout(store.forwardTimeout)
            store.forwardTimeout = setTimeout(()=>{
                store.isForward = false
            },500)
        }else {
            store.isRewind = true;
            if(store.rewindTimeout)
                clearTimeout(store.rewindTimeout)
            setTimeout(()=>{
                store.isRewind = false
            },500)
        }
        if(!store.isLoaded)
            return;
        store.handleControlsShow();
        let newPlayedSecond = store.playedSeconds+changePlayedSeconds;
        if(newPlayedSecond >= store.playedDuration){
            newPlayedSecond = store.playedDuration;
        }else if(newPlayedSecond < 0){
            newPlayedSecond = 0;
        }
        store.changeSeek(newPlayedSecond/store.playedDuration)
    }

    /**
     * 볼륨바 클릭 소리 크기 변경
     * @param e
     */
    handleVolumeChange = value => {
        let store = this;

        store.changeVolume(value);
    }

    /**
     * 영상 준비 완료
     */
    handleReady = () => {
        let store = this;
        store.isLoaded = true;
    }

    /**
     * 영상 종료
     */
    handleEnded = () => {
        this.isPlay = false;
    }

    /**
     * 영상 길이 입력
     * @param e
     */
    handleDuration = (e) => {
        let store = this;
        let {id} = store.selectedFile;
        store.fileMap.set(id,{
            ...store.fileMap.get(id),
            history:{
                ...store.fileMap.get(id).history,
                playedDuration: e,
                created:moment().format()
            },
            viewDate : moment().format()
        })
        store.playedDuration = e;

    }

    /**
     * 영상 재생정보 입력
     * @param state
     */
    handleProgress = (state) => {
        let {
            loaded,
            loadedSeconds,
            played,
            playedSeconds
        } = state;
        let store = this;
        let {id} = store.selectedFile;
        store.loaded = loaded;
        store.loadedSeconds = loadedSeconds;
        if (!store.seeking) {
            store.fileMap.set(id,{
                ...store.fileMap.get(id),
                history:{
                    ...store.fileMap.get(id).history,
                    playedSeconds : playedSeconds,
                    created:moment().format()
                }
            });
            store.playedSeconds = playedSeconds;
        }

    }

    /**
     * 영상 KeyDown 이벤트
     * @param e
     */
    handleKeyDown = e =>{
        let store = this;
        let keyCode = e.keyCode;
        if(keyCode === 32){
            e.preventDefault();
            store.handlePlay();
        }
        if(keyCode === 39){
            e.preventDefault();
            store.changeSeek(store.playedSeconds + 5);
        }
        if(keyCode === 37){
            e.preventDefault();
            store.changeSeek(store.playedSeconds - 5);
        }
        if(keyCode === 38){
            e.preventDefault();
            store.changeVolume(store.volume + 0.1);
        }
        if(keyCode === 40){
            e.preventDefault();
            store.changeVolume(store.volume - 0.1);
        }
        if(keyCode === 77){
            store.toggleMuted();
        }
        if(keyCode === 70){
            store.toggleCinema();
        }
        if(keyCode === 67){
            store.toggleTrack();
        }
    }

    /**
     * 영상 KeyUp 이벤트
     * @param e
     */
    handleKeyUp = e =>{
        let store = this;
        let keyCode = e.keyCode;
        if(keyCode === 39){
            e.preventDefault();
            store.handleSeekKeyUp();
        }
        if(keyCode === 37){
            e.preventDefault();
            store.handleSeekKeyUp();
        }
    }

    /**
     * 영상 Error 핸들러
     * @param e
     */
    handleError = e => {
        let store = this;
        console.error(e)
    }

    /**
     * 전체화면 토글이벤트
     * @param e
     */
    toggleFullScreen = e => {
        if (this.isFullScreeMode) {
            this.isFullScreeMode = false;
            fscreen.exitFullscreen();
        } else {
            this.isFullScreeMode = true;
            fscreen.requestFullscreen(playerStore.playerRef.current);
        }
    }

    /**
     * 컨트롤러 숨김처리
     */
    handleControlsHide = e => {
        e.preventDefault();
        let store = this;
        if(store.isPlay){
            if(!store.seeking) {
                store.isControlsHide = true;
            }
        }
    }

    /**
     * 컨트롤러 보임처리
     */
    handleControlsShow = e => {
        let store = this;
        store.isControlsHide = false;
        if(store.timer) {
            clearTimeout(store.timer);
        }
        if(store.isPlay){
            if(!store.seeking){
                store.timer =
                    setTimeout(()=>{
                            store.isControlsHide = true;
                    },1500)
            }
        }
    }

    /**
     *
     * @param changeVolume
     */
    changeVolume = changeVolume => {
        let store = this;
        let newVolume = parseFloat(changeVolume);
        if(newVolume >= 1){
            newVolume = 1;
        }else if(newVolume < 0.1){
            newVolume = 0;
        }
        this.volume = newVolume;
        window.localStorage.setItem("volume", newVolume.toString());
    }

    togglePip = () => {
        this.isPip = !this.isPip;
    }

    toggleTrack = () => {
        if(this.isUseTrack){
            document.querySelector('track').track.mode = 'hidden'
        }else{
            document.querySelector('track').track.mode = 'showing'
        }


    }

    handlePlay = (e) => {
        let store = this;
        store.isPlay = !store.isPlay;
        store.isPlayPause = true;
        setTimeout(()=>{
            store.isPlayPause = false;
        },500)
        store.handleControlsShow();
    }

    @computed
    get isPlaying(){
        let store = this;
        if(store.isPause || !store.isLoaded){
            return false;
        }else{
            return store.isPlay
        }
    }

    @computed
    get volumeIcon(){
        let store = playerStore;
        if(store.isMute){
            return <FiVolumeX size={`1.5rem`} color={'#fff'}/>;
        }else if(store.volume > 0.5){
            return <FiVolume2 size={`1.5rem`} color={'#fff'}/>;
        }else if(store.volume > 0){
            return <FiVolume1 size={`1.5rem`} color={'#fff'}/>;
        }else{
            return <FiVolume size={`1.5rem`} color={'#fff'}/>;
        }
    }

    toggleMuted = () => {
        let store = this;
        window.localStorage.setItem("isMute", !store.isMute);
        store.isMute = !store.isMute;
    }


    handleBuffer = e => {
        let store = this;
        store.isBuffered = true;
    }

    handleBufferEnd = e => {
        let store = this;
        store.isBuffered = false;
    }

    toggleCinema = () => {
        this.isCinema = !this.isCinema;
    };

    @observable
    isCinema = false;

    toggleLike = file => {
        let store = this;
        let isLike = file?.isLike
        let url;
        let likeCount = file?.likeCount || 0;
        if(isLike){
            url = `/media/un-like`;
            likeCount = likeCount-1;
        }else{
            url = `/media/like`;
            likeCount = likeCount+1;
        }
        axios.post(url, file).then(r => {
            notificationStore.notification(`${file.name} 좋아요 ${isLike ? '취소' : ''}`, '성공')
            store.fileMap.set(file.id, {...file, isLike: !isLike, likeCount, likeDate: moment().format()});
        })

    }

    toggleHate = file => {
        let store = this;
        let url;
        let isHate = file?.isHate
        let hateCount;
        if(isHate){
            url = `/media/un-hate`;
            hateCount = file.hateCount-1;
        }else{
            url = `/media/hate`;
            hateCount = file.hateCount+1;
        }
        axios.post(url, file).then(r => {
            notificationStore.notification(`${file.name} 싫어요 ${isHate ? '취소' : ''}`, '성공')
            store.fileMap.set(file.id, {...file, isHate: !isHate, hateCount });
        })
    }
}

export const playerStore = new PlayerStore();
