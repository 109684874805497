import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {MdOutlinePlaylistPlay} from "react-icons/md";
import {GiBackwardTime} from "react-icons/gi";
import Styles from './StorageView.module.scss'
import ContentWrap from "../../components/layout/ContentWrap";
import {historyMediaStore} from "../../store/MediaStore";
import Media from "../../components/media/Media";
import {navigate} from "../../helper/HistoryHelper";
import playListStore from "../../store/PlayListStore";
import PlayList from "../../components/media/PlayList";
import {FaPlus} from "react-icons/fa";
import axios from "axios";
import moment from "moment";

@observer
class StorageView extends Component {

    componentDidMount() {
        historyMediaStore.debounceRefresh()
        playListStore.load()
    }

    render() {
        return (
            <ContentWrap>
                <div className={Styles.Content}>
                    <div className={Styles.ListWrap}>
                        <div className={Styles.Header}>
                            <div className={Styles.Title}>
                                <GiBackwardTime size={`1.5rem`} className={`me-2`}/> 시청기록
                            </div>
                            <div className={Styles.Link}>
                                <a className={``}
                                   onClick={()=>{
                                       navigate("/main/history")
                                   }}
                                >
                                    모두 보기
                                </a>
                            </div>
                        </div>
                        <div className={Styles.List}>
                            {
                                historyMediaStore.files.slice(0,10).map((file,idx)=>
                                    <div style={{minWidth:300,maxWidth:300}} className={`pe-3`} key={`STORAGE_HISTORY_${idx}`}>
                                        <Media file={file}
                                               onClick={(file)=>{
                                                   navigate(`/main/file/${file?.folder?.id}/${file.id}`)
                                               }}
                                               dateLabel={`${moment(file?.history.created).fromNow()} 시청함`}
                                        />
                                    </div>
                                )

                            }
                        </div>
                    </div>
                    <br/>
                    <div className={Styles.ListWrap}>
                        <div className={Styles.Header}>
                            <div className={Styles.Title}>
                                <MdOutlinePlaylistPlay size={`1.5rem`} className={`me-2`}/> 재생 목록
                            </div>
                            <div className={Styles.Link}>

                            </div>
                        </div>
                        <div className={Styles.List}>
                            {
                                playListStore.playLists.map((playList, idx)=>
                                    <div style={{minWidth:300, maxWidth:300}} className={`pe-3`} key={`STORAGE_PLAYLIST_${idx}`}>
                                        <PlayList playList={playList}/>
                                    </div>
                                )

                            }
                            <div style={{minWidth:150}} className={`d-flex justify-content-center align-items-start`}>
                                <div className={Styles.PlayListAddButton}
                                     onClick={()=>{
                                         axios.post(`/play/list/insert`,{})
                                             .then(r=>playListStore.load())
                                     }}
                                >
                                    <FaPlus/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrap>
        );
    }
}

StorageView.propTypes = {};

export default StorageView;
