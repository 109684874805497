import React from 'react';
import {computed, makeObservable, observable, reaction, values} from "mobx";
import axios from "axios";
import CommonHelper from "../helper/CommonHelper";
import Sort from "./Sort";
import {debounce} from "lodash";

class TagStore {

    constructor({type = `ACTOR`, rowsPerPage = 40, sort = new Sort('id', Sort.SORT_DIRECTION.DESC)}) {
        makeObservable(this);
        this.type = type;
        this.rowsPerPage = rowsPerPage;
        this.sort = sort;
    }

    @observable
    isInit = true;

    @observable
    type;

    @observable page = 1;

    @observable isFetching = false;

    /**
     * 정렬 컬럼
     * @type Sort
     */
    @observable sort;

    /**
     * 현재 검색 조건
     * @type {Map}
     */
    @observable searchCondition = new Map();

    /**
     * 페이징 블럭 사이즈
     * @type {number}
     */
    paginationSize;

    /**
     * 페이지당 행 수
     * @type {number}
     */
    rowsPerPage;

    scrollRef = React.createRef();

    @observable
    scrollTop = 0;

    onScrollHandler = e => {
        let store = this;
        store.scrollTop = e.target.scrollTop;
    }

    @computed
    get searchConditionWithPage() {
        let m = CommonHelper.mapToObject(this.searchCondition);
        m.pagingOption = {
            page: this.page,
            rowsPerPage: this.rowsPerPage,
            sort: this.parameterizeSort
        };
        return {...m, type:this.type};
    }

    @computed
    get parameterizeSort() {
        return this.sort.toParam()
    }

    debounceFetch = debounce(reset => {
        if(reset){
            this.clear()
            this.page = 1;
        }else{
            this.page += 1;
        }
        this.fetch(reset)
    },500
    )

    debounceRefresh = debounce(() => {
        this.clear()
        this.fetch(true)
        },500
    )

    fetch = async (reset = false) => {
        let store = this;
        if (store.isFetching)
            return;
        store.isFetching = true;

        let {data: {items, itemCount}} = await axios.post(`/tag/list`, this.searchConditionWithPage)
        let originFiles = store.tags
        store.tagMap.replace(originFiles.concat(items).map(tag => [tag.id, tag]))
        store.count = itemCount;
        store.isFetching = false;
        store.isInit = false;
        return originFiles.concat(items).map(media => [media.id, media]);
    }

    @computed
    get isMore() {
        let store = this;
        return store.tags.length < store.count;
    }

    @observable
    tagMap = new Map();

    @computed
    get tags() {
        return values(this.tagMap) || [];
    }

    @observable
    count = 0;

    clear() {
        this.count = 0
        this.tagMap.clear();
    }


}


const actorTagStore = new TagStore({
    type : 'ACTOR'
});

export {actorTagStore};
