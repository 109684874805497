import React from 'react';
import {observer} from 'mobx-react';
import Styles from './MobileNavBar.module.scss';
import {computed, makeObservable, observable} from "mobx";
import {FaArrowDown, FaHome, FaList, FaSearch, FaTags} from "react-icons/fa";
import {AiFillHome} from "react-icons/ai";
import {navigate} from "../../helper/HistoryHelper";
import appStore from "../../store/AppStore";
import {
    MdFeaturedPlayList,
    MdHome,
    MdOutlinePlaylistPlay,
    MdPlaylistPlay,
    MdSearch,
    MdThumbUp,
    MdVideoLibrary
} from "react-icons/md";
import {Button, FormControl, InputGroup, Nav, OverlayTrigger, Tab, Tabs, Tooltip} from "react-bootstrap";
import {GiHamburgerMenu} from "react-icons/gi";
import {Home, SearchNormal} from "iconsax-react";

@observer
class MobileNavBar extends React.Component {

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @observable
    isSearchMode = false;

    render() {
        return (
            <div className={Styles.MobileNavBar}>
                <Nav fill variant="underline" defaultActiveKey="home" >
                    <Nav.Item>
                        <Nav.Link eventKey="category"
                                  className={Styles.NavLink}
                                  onClick={e=>{
                                      navigate('/main/category')
                                  }}
                        >
                            <FaList size={`1.5rem`}/>
                            <div className={Styles.Name}>
                                카테고리
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="home"
                                  className={Styles.NavLink}
                                  onClick={e=>{
                                      navigate('/main/home')
                                  }}
                        >
                            <MdHome size={`1.5rem`}/>
                            <div className={Styles.Name}>
                                홈
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-1"
                                  className={Styles.NavLink}
                                  onClick={e=>{
                                      navigate('/main/storage')
                                  }}
                        >
                            <MdVideoLibrary size={`1.5rem`}/>
                            <div className={Styles.Name}>
                                보관함
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

        );
    }

}

export default MobileNavBar;
