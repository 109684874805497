import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from './ContentWrap.module.scss'

@observer
class ContentWrap extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        return (
            <div className={Styles.Content}>
                {
                    this.props.children
                }
            </div>
        );
    }
}

ContentWrap.propTypes = {};

export default ContentWrap;