import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import Styles from './Media.module.scss'
import {Button, Image} from 'react-bootstrap';
import {THUMBNAIL_BASE_URL} from "../../const/AppConst";
import CommonHelper from "../../helper/CommonHelper";
import {navigate} from "../../helper/HistoryHelper";
import {Img} from "react-image";
import moment from "moment";
import {MdThumbUp} from "react-icons/md";
import Sort from "../../store/Sort";
import {FaSortDown, FaSortUp} from "react-icons/fa";
@observer
class SortButton extends Component {

    get sortIcon() {
        let {store, title ,id} = this.props
        if (JSON.stringify(store.sort.id) === JSON.stringify(this.props.id)) {
            return store.sort.direction === Sort.SORT_DIRECTION.ASC ?
                <FaSortUp color={`#000`}/> : <FaSortDown color={'#000'}/>
        } else {
            return null;
        }
    }
    render() {
        let {store, title ,id} = this.props
        return (
            <Button variant={store.sort.id === id ? "light":'secondary'} size={'sm'} className={`me-2`}
                    onClick={()=>{
                        if(store.sort.id !== id) {
                            store.sort = new Sort(id, Sort.SORT_DIRECTION.DESC)
                        }else {
                            if(store.sort.direction === Sort.SORT_DIRECTION.DESC) {
                                store.sort = new Sort(id, Sort.SORT_DIRECTION.ASC)
                            }else {
                                store.sort = new Sort(id, Sort.SORT_DIRECTION.DESC)
                            }
                        }
                        store.debounceRefresh();
                    }}
            >
                {title} {this.sortIcon}
            </Button>
        );
    }
}

SortButton.propTypes = {};

export default SortButton;