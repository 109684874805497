import {computed, makeObservable, observable, values} from "mobx";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import notificationStore from "./NotificationStore";

class PlayListStore {

    constructor() {
        makeObservable(this);
    }

    load = async () => {
        let {data} = await axios.post(
            `/play/list`,
            {
                media : this.media
            }
        );
        this.playMap.replace(data.map(playList=>([playList.id, playList])))

    }

    @observable
    playMap = new Map();

    @computed
    get playLists(){
        return values(this.playMap)
    }

    @observable
    show = false;

    open(media){
        let store = this;
        store.show = true;
        store.media = media;
        store.load();
    }

    close = () => {
        let store = this;
        store.media = undefined
        store.show = false;
    }

    @observable
    media;

    togglePlayList = async (playList) => {
        let url;
        let message;
        if(playList?.isHave){
            url = `/play/list/remove`;
            message = `${playList?.name}에서 제거 되었습니다.`
        }else{
            url = `/play/list/add`;
            message = `${playList?.name} 목록에 추가 되었습니다.`
        }
        let {data} = await axios.post(
            url,
            {
                ...playList,
                targetMedia : this.media
            }
        );
        notificationStore.notification(message, '성공')
        this.load();
    }

    @observable
    showModify = false;

    @observable
    selectPlayList = false;

    saveCallback;

    openModify(playList, callback){
        let store = this;
        store.showModify = true;
        store.selectPlayList = playList;
        store.saveCallback = callback;
        store.load();
    }

    closeModify = () => {
        let store = this;
        store.selectPlayList = undefined
        store.showModify = false;
    }
}

const playListStore = new PlayListStore();
export default playListStore;