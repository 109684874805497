import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {recentlyMediaStore} from "../../store/MediaStore";
import ContentWrap from "../../components/layout/ContentWrap";
import InfiniteScroll from "react-infinite-scroller";
import Media from "../../components/media/Media";
import {Button, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import Styles from './ActorView.module.scss'
import Sort from "../../store/Sort";
import {isMobile} from "react-device-detect";
import {navigate, navigateReplace} from "../../helper/HistoryHelper";
import {Skeleton} from "antd";
import {MagnifyingGlass} from "react-loader-spinner";
import SortButton from "../../components/media/SortButton";
import {actorTagStore} from "../../store/TagStore";
import Actor from "../../components/actor/Actor";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

@observer
class ActorView extends Component {

    constructor(props) {
        super(props);
        this.state={
            keyword : ''
        }

    }

    componentDidMount() {
        let store = actorTagStore;
        setTimeout(()=>{
            store.scrollRef.current.scrollTo(0, store.scrollTop)
        }, 1000)
        if(store.isInit){
            store.debounceRefresh();
        }
    }

    componentWillUnmount() {
    }

    render() {
        let store = actorTagStore;
        return (
            <ContentWrap>
                <div className={Styles.SearchBar}>
                    <Container className={`p-0 d-flex justify-content-between`}>
                        <div className={`d-flex flex-grow-1 text-nowrap`}>
                            <SortButton id={'id'}
                                        title={'등록순'}
                                        store={store}
                            />
                            <SortButton id={'mediaCount'}
                                        title={'영상수'}
                                        store={store}
                            />
                        </div>
                        <div>
                            <InputGroup>
                                <FormControl size={'sm'}
                                             value={this.state.keyword}
                                             onChange={e=>{
                                                 this.setState({keyword:e.target.value});
                                             }}
                                             onKeyDown={e=>{
                                                 if(e.key === 'Enter') {
                                                     store.searchCondition.set("keyword", this.state.keyword)
                                                     store.debounceFetch(true);
                                                 }
                                             }}
                                />
                                <Button variant={"outline-light"} size={'sm'}
                                        onClick={()=>{
                                            store.searchCondition.set("keyword", this.state.keyword)
                                            store.debounceFetch(true);
                                        }}
                                >
                                    검색
                                </Button>
                            </InputGroup>
                        </div>
                    </Container>
                </div>
                <div className={Styles.Content}
                     ref={store.scrollRef}
                     onScroll={store.onScrollHandler}
                >
                    <InfiniteScroll
                        loadMore={(page) => {
                            store.debounceFetch(false)
                        }}
                        hasMore={store.tags.length < store.count}
                        loader={
                            <div key={"a"}>
                            </div>
                        }
                        useWindow={false}
                        threshold={5}
                    >
                        <Container className={`p-0`}>
                            <ResponsiveMasonry columnsCountBreakPoints={{576: 2, 768: 2, 992: 3, 1200: 4, 1400: 5}}

                            >
                                <Masonry className={Styles.Masonry} gutter={`1rem`}>
                                {
                                    !store.isFetching && store.tags.length < 1 &&
                                    <div className={`align-items-center justify-content-center d-flex p-5`}>
                                        검색 결과가 없습니다.
                                    </div>
                                }
                                {
                                    store.tags.map(tag => {
                                        return (
                                            <div key={`ACTOR-${tag.id}`} >
                                                <Actor tag={tag} store={store}/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    store.isFetching &&
                                    <Col sm={12} md={12} xl={12} className={`d-flex justify-content-center align-items-center p-5`}>
                                        <MagnifyingGlass
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="magnifying-glass-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="magnifying-glass-wrapper"
                                            glassColor="#c0efff"
                                            color="#afafaf"
                                        />

                                    </Col>
                                }
                                </Masonry>
                            </ResponsiveMasonry>

                        </Container>
                    </InfiniteScroll>
                </div>
            </ContentWrap>
        );
    }
}

ActorView.propTypes = {};

export default ActorView;
